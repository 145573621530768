<template>
  <div class="home_button_wrapper d_flex align_center" v-scroll-reveal.reset="{delay: 700}">
    <a :href="to">{{text}}</a>
    <i
      class="fas fa-long-arrow-alt-right"
      v-scroll-reveal.reset="{delay: 1000, origin: 'left'}"
      v-show="isArrow"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    isArrow: {
      type: Boolean,
      default: true,
      required: false
    },
    to: {
      type: String,
      required: true,
      default: "/"
    }
  }
};
</script>

<style lang="scss">
.home_button_wrapper {
  position: relative;
  animation: FadeUp 0.9s ease-in-out 1s 1 normal both;
  a {
    padding: 15px 40px;
    border: 1px solid $primary;
    width: 100%;
    color: $secondary;
    box-shadow: inset 0 0 0 0 $secondary;
    transition: ease-in-out 0.6s;

    &:hover {
      border-color: transparent;
      color: white;
      box-shadow: inset 400px 0 0 100px $secondary;
    }
  }

  i {
    font-size: 35px;
    color: $secondary;
    position: absolute;
    right: -20px;
    animation: bounceLeft 0.9s ease-in-out 3 normal both;
  }

  &:hover {
    i {
      animation: animatedTurn 0.9s ease-in-out 1 normal both;
    }
  }
}

@media screen and (max-width: 500px) {
  
}
</style>